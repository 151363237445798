import Swiper, { Navigation } from 'swiper'

import 'swiper/css'

document.addEventListener('DOMContentLoaded', () => {
	const block = 'b-news-slider'

	new Swiper(`.${block}__swiper`, {
		modules: [Navigation],
		loop: true,
		slidesPerView: 1,
		spaceBetween: 20,
		breakpoints: {
			767: {
				slidesPerView: 2,
			},
			999: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: `.${block}__next`,
			prevEl: `.${block}__prev`,
		},
	})
})