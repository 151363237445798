import 'style/layout/work';
import 'block/common';
import 'block/header'
import 'block/layout'
import 'block/main'
import 'block/footer'

document.addEventListener('DOMContentLoaded', () => {
	//
})
