document.addEventListener('DOMContentLoaded', () => {
	const block = 'b-top-block';

	if(document.querySelector(`.${block}__video`)) {
		const video = document.querySelector(`.${block}__video`);

		video.autoplay = true;
		video.load();

		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
});